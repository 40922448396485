<template>
  <div class="hello">
    <div class="row-center">
      <img src="yinto.png"> 
      <h2> Deposit YTO Drop USDT </h2>
    </div>
    <div>
      <span> Total Deposited YTO: {{ totalstaked }} </span>
      <br>
      <span> My YTO Balance: {{ yinBalance }} </span>
      <br>
      <span> My USDT Balance: {{ usdtBalance }} </span>
      <br>
      <label> My Deposited YTO: {{ staked }} </label> 
      <br>
      <label> MyDrop: {{ pendingUsdt }}  </label>
      <br>
      <div class="row-center">
        <el-button @click="settlementAll"> GetMydrop </el-button>
      </div>
    </div>

    <div class="row-center">
      <el-input placeholder="Amount" v-model="amountbuy" type="number" />
      <el-button @click="BuyLiquor"> 1. Buy Liquor(單純買酒) </el-button>
    </div>

    <div class="row-center">
      <el-input placeholder="YTO Amount" v-model="amount" type="number" />
      <el-button @click="deposit"> 2. Deposit YTO(存入YTO) </el-button>
    </div>

    <div class="row-center">
      <el-input placeholder="YTO Amount" v-model="wdAmount" type="number" />
      <el-button @click="withdraw"> 3. Withdraw YTO(取出YTO) </el-button>
    </div>


    <!-- <div class="row-center">
      <el-button @click="approve"> 6. Approve YTO(授權 YTO) </el-button>

    </div>
    <div class="row-center">
      <el-button @click="approveMoon"> 7. Approve USDT(授權 USDT) </el-button>
    </div>
    </div>
        <input placeholder="USDT数量（如当天的分红金额）" v-model="reward" type="number" />
        <button @click="approveUSDT"> 授权 USDT (授权一次即可) </button>
        <button @click="addBonus"> 存入分红金 </button>
    <div> -->

  
  </div>
</template>

<script>
/* eslint-disable */
import { fromDec, toDec, MaxUint } from '../decUtil.js';


export default {
  name: 'Farming',
  data() {
    return {
      yinBalance: null,
      totalstaked: null,
      amount: null,
      wdAmount: null,
      staked: null,
      pendingUsdt: null,
      usdtBalance: null,
      amountbuy: null,
      amountservice: null,
      reward: null,
      isUSDTApprovedToMoon: false,
      isYTOApprovedToFarming: false,
      newComers: null,
    }
  },

  created() {
    this.init()
  },

  methods: {
      async init() {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          this.account = window.tronWeb.defaultAddress.base58;
          this.tronWeb = window.tronWeb;
          console.log("inited account:" + this.account);

          this.initContract();

        } else {
          setTimeout(() => {
            this.init()
          }, 1000);
        }
      },

    async initContract() {
      this.yin = await this.tronWeb.contract().at("TTEi9VzMv7rTpFAodXhtZCn6BoLLacpio8");
      this.usdt = await this.tronWeb.contract().at("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t");
      this.farming = await this.tronWeb.contract().at("TLh1WvoJ87aXzbTA49uajfq1pmHoRErqeB");
      this.newtomoon = await this.tronWeb.contract().at("TBz7nHfY5ZXczHhwVSnZeUMyJuR9rCbDX1");
      

      this.getBalance()
      this.getPendingUsdt();
    },


    async addBonus() {
      let amount = toDec(this.reward, 6);
      this.farming.addBonus(amount).send({from: this.account}).then( () => {
        this.getPendingUsdt();
      })
    },

    async getBalance() {
      let bofU = await this.yin.balanceOf(this.account).call();
      this.yinBalance =  fromDec(bofU.toString(), 18)

      bofU = await this.yin.balanceOf(this.farming.address).call();
      this.totalstaked =  parseInt(fromDec(bofU.toString(), 18))

      bofU = await this.usdt.balanceOf(this.account).call();
      this.usdtBalance = fromDec(bofU.toString(), 6)
    },

    getPendingUsdt() {
      this.farming.pendingUsdt(this.account).call().then(r => {
        this.pendingUsdt = this.tronWeb.fromSun(r.toString())
      })

      this.farming.userInfo(this.account).call().then(r => {
        this.staked = fromDec(r.amount.toString(), 18);
      });
      
    },

    approve() {
      this.yin.approve(this.farming.address, MaxUint).send({
        from: this.account
        // feeLimit: 20000000
      })
    },

    async isYTOApproveFarming(amount) {
      let r = await this.yin.allowance(this.account, this.farming.address).call();
      if (parseFloat(fromDec(r.toString(), 18)) >= amount) {
        this.isYTOApprovedToFarming = true;
      } else {
        this.isYTOApprovedToFarming = false;
      }
    },

    approveMoon() {
      this.usdt.approve(this.newtomoon.address, MaxUint).send({
        from: this.account
        // feeLimit: 20000000
      })
    },

    async isUSDTApproveMoon() {
      let r = await this.usdt.allowance(this.account, this.newtomoon.address).call();
      if (parseFloat(this.tronWeb.fromSun(r.remaining.toString())) >= 72000) {
        console.log("isUSDTApprovedToMoon")
        this.isUSDTApprovedToMoon = true;
      } else {
        this.isUSDTApprovedToMoon = false;
      }
    },

    async BuyService() {
      if (isNaN(parseInt(this.amountservice)) || parseInt(this.amountservice) <= 0) {
        this.$message("Invalid amount");
        return ;
      }

      await this.isUSDTApproveMoon();
      if (this.isUSDTApprovedToMoon) {
        this.newtomoon.BuyService(parseInt(this.amountservice)).send({
          from: this.account,
          feeLimit: 300000000
        }).then(() => {
          this.getBalance();
          this.getPendingUsdt();
        })
      } else {
        this.$confirm('Need approve first, Then retry Buy Service', 'Tips', {
          confirmButtonText: 'Approve',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.approveMoon();
        }).catch(() => {
        });
      }
    },

    async BuyLiquor() {
      if (isNaN(parseInt(this.amountbuy)) || parseInt(this.amountbuy) <= 0) {
        this.$message("Invalid amount");
        return ;
      }

      await this.isUSDTApproveMoon();
      console.log("isUSDTApprovedToMoon:" + this.isUSDTApprovedToMoon);
        if (this.isUSDTApprovedToMoon) {
        this.newtomoon.BuyLiquor(parseInt(this.amountbuy)).send({
          from: this.account,
          feeLimit: 300000000
        }).then(() => {
          this.getBalance();
          this.getPendingUsdt();
        }) 
      }
      else {
        this.$confirm('Need approve first, Then retry Buy Liquor', 'Tips', {
          confirmButtonText: 'Approve',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.approveMoon();
        }).catch(() => {
        });
      }

    },

    approveUSDT() {
      this.usdt.approve(this.farming.address, MaxUint).send({
        from: this.account,
        feeLimit: 100000000
      })
    },

    AddNewComers() {
      this.newtomoon.AddNewComers(this.newComers).send({
        from: this.account,
        feeLimit: 100000000
      });
    },

    settlementAll() {
      this.farming.deposit(0, this.account).send({
        from: this.account,
        feeLimit: 100000000
      }).then(() => {
        this.getPendingUsdt()
        this.getBalance();
      })
    },

    async deposit() {
      if (isNaN(parseFloat(this.amount)) || parseFloat(this.amount) <= 0) {
        this.$message("Invalid amount");
        return ;
      }

      await this.isYTOApproveFarming(parseFloat(this.amount));
      if (this.isYTOApprovedToFarming) {
        let amount = toDec(this.amount, 18);
        this.farming.deposit(amount, this.account).send({
          from: this.account,
          feeLimit: 100000000
        }).then(() => {
          this.getPendingUsdt()
          this.getBalance();
        })
      } else {
        this.$confirm('Need approve first, Then retry Deposit YTO', 'Tips', {
          confirmButtonText: 'Approve',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.approve();
        }).catch(() => {
        });
      }

    },

    withdraw() {
    if (isNaN(parseFloat(this.wdAmount)) || parseFloat(this.wdAmount) <= 0) {
      this.$message("Invalid amount");
      return ;
    }

      let amount = toDec(this.wdAmount, 18);
      this.farming.withdraw(amount).send({
        from: this.account
        // feeLimit: 20000000
      }).then(() => {
        this.getPendingUsdt()
        this.getBalance();
      })
    }    

  }
}
</script>
