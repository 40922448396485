<template>
  <div id="app">
    <Farming />
  </div>
</template>

<script>
import Farming from './components/Farming.vue'

export default {
  name: 'App',
  components: {
    Farming
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}

.row-center {
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  justify-content: center;
}

.el-message-box__wrapper .el-message-box {
  width: 340px;
}

img {
  height: 40px;
  margin-top: 10px;
  padding: 5px;
}


</style>
